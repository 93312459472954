import React from 'react';

const textLayout = {
    vertical: {
        title: {
            textAnchor: 'start',
            x: 40,
        },
        attributes: {},
        attribute: {
            x: 40,
            dy: '1.2em',
        },
    },
    horizontal: {
        title: {
            textAnchor: 'start',
            y: 40,
        },
        attributes: {
            x: 0,
            y: 40,
        },
        attribute: {
            x: 0,
            dy: '1.2em',
        },
    },
};

// https://stackoverflow.com/questions/5623838/rgb-to-hex-and-hex-to-rgb
function componentToHex(c) {
    var hex = c.toString(16);
    // https://stackoverflow.com/questions/3735939/jslint-expected-and-instead-saw
    return hex.length === 1 ? "0" + hex : hex;
}
function rgbToHex(r, g, b) {
    return "#" + componentToHex(r) + componentToHex(g) + componentToHex(b);
}

// https://stackoverflow.com/questions/72050280/react-table-column-cells-conditional-formatting-with-color-scale
function pickHex(color1, color2, weight) {
    var w1 = weight;
    var w2 = 1 - w1;
    var rgb = [
        Math.round(color1[0] * w1 + color2[0] * w2),
        Math.round(color1[1] * w1 + color2[1] * w2),
        Math.round(color1[2] * w1 + color2[2] * w2)
    ];
    // return rgbToHex(rgb);
    return rgb
}
// const green = [0, 255, 0];
const white = [255, 255, 255];
const red = [255, 0, 0];

const PureSvgNodeElement = ({ nodeDatum, orientation, toggleNode, onNodeClick }) => {
    // console.log(onNodeClick)
    // onNodeClick()
    // console.log(nodeDatum)
    // console.log(pickHex(red,white,parseFloat(nodeDatum.counter) + .9))
    var fill = "black"
    if ("prop_mean" in nodeDatum) {
        fill = pickHex(red, white, parseFloat(nodeDatum.prop_mean))
        //  [255, 204, 204]
        // console.log(fill)
        // console.log(rgbToHex(fill[0], fill[1], fill[2]))
        fill = rgbToHex(fill[0], fill[1], fill[2])
        // #ffcccc
    }
    // console.log(nodeDatum)
    // console.log(nodeDatum.fullname)
    // console.log(toggleNode)
    // this.setState({text_nodevalue: nodeDatum.fullname})
    return (
        <>
            {
                "children" in nodeDatum
                    ? <circle r={40} fill={fill} onClick={function (event) { toggleNode(); onNodeClick(event, nodeDatum) }}></circle>
                    : <rect height={50} width={50} fill={fill} onClick={function (event) { onNodeClick(event, nodeDatum) }}></rect>}
            <g className="rd3t-label">
                <text
                    className="rd3t-label__title"
                    {...textLayout[orientation].title}
                    onClick={onNodeClick}
                >
                    {nodeDatum.name}
                </text>
                <text className="rd3t-label__attributes" {...textLayout[orientation].attributes}>
                    {nodeDatum.attributes &&
                        Object.entries(nodeDatum.attributes).map(([labelKey, labelValue], i) => (
                            <tspan key={`${labelKey}-${i}`} {...textLayout[orientation].attribute}>
                                {labelKey}: {labelValue}
                            </tspan>
                        ))}
                </text>
            </g>
        </>
    );
};

export default PureSvgNodeElement;
