import React, { Component } from 'react';
import Tree from 'react-d3-tree';
import { version } from 'react-d3-tree/package.json';
import Switch from './components/Switch';
import MixedNodeElement from './components/MixedNodeElement';
import PureSvgNodeElement from './components/PureSvgNodeElement';
import MixedNodeInputElement from './components/MixedNodeInputElement';
import './App.css';
// import Slider, { Range } from 'rc-slider';
import Slider from "rc-slider";
// import { Slider, Rail, Handles, Tracks } from 'react-compound-slider';

import "rc-slider/assets/index.css";
import _ from 'lodash';

// Data examples
import orgChartJson from './examples/org-chart.json';
// import flareJson from './examples/d3-hierarchy-flare.json';
// import reactTree from './examples/reactRepoTree';
// import debugTree from './examples/debug_flowsim.json';
// import { saveAs } from 'file-saver';
import FileSaver from 'file-saver';
// FileSaver saveAs(Blob/File/Url, optional DOMString filename, optional Object { autoBom })

console.log('Demo React version: ', React.version);

const debugging_urls = false;
const vissim_version = "1.9"

const customNodeFnMapping = {
    svg: {
        description: 'Default - Pure SVG node & label (IE11 compatible)',
        fn: (rd3tProps, appState) => (
            <PureSvgNodeElement
                nodeDatum={rd3tProps.nodeDatum}
                toggleNode={rd3tProps.toggleNode}
                orientation={appState.orientation}
                onNodeClick={rd3tProps.onNodeClick}
            />
        ),
    },
    // svg_no_toggle: {
    //     description: 'Default - Pure SVG node & label (IE11 compatible)',
    //     fn: (rd3tProps, appState) => (
    //         <PureSvgNodeElement
    //             nodeDatum={rd3tProps.nodeDatum}
    //             toggleNode={"dont_toggle"}
    //             orientation={appState.orientation}
    //             onNodeClick={rd3tProps.onNodeClick}
    //         />
    //     ),
    // },
    mixed: {
        description: 'MixedNodeElement - SVG `circle` + `foreignObject` label',
        fn: ({ nodeDatum, toggleNode }, appState) => (
            <MixedNodeElement
                nodeData={nodeDatum}
                triggerNodeToggle={toggleNode}
                foreignObjectProps={{
                    width: appState.nodeSize.x,
                    height: appState.nodeSize.y,
                    x: -50,
                    y: 50,
                }}
            />
        ),
    },
    input: {
        description: 'MixedNodeElement - Interactive nodes with inputs',
        fn: ({ nodeDatum, toggleNode }, appState) => (
            <MixedNodeInputElement
                nodeData={nodeDatum}
                triggerNodeToggle={toggleNode}
                foreignObjectProps={{
                    width: appState.nodeSize.x,
                    height: appState.nodeSize.y,
                    x: -50,
                    y: 50,
                }}
            />
        ),
    }
};

const countNodes = (count = 0, n) => {
    // Count the current node
    count += 1;

    // Base case: reached a leaf node.
    if (!n.children) {
        return count;
    }

    // Keep traversing children while updating `count` until we reach the base case.
    return n.children.reduce((sum, child) => countNodes(sum, child), count);
};

const throttled_simcolor = _.throttle((self, slider_value) => {
    self.get_FlowSimColor(self.state.flowsim_sim_name, self.state.text_node_fullname, slider_value)
    console.log("throttled call")
}, 150 // milliseconds to update the color, first update fires instantly
)

class App extends Component {
    constructor() {
        super();

        this.addedNodesCount = 0;

        this.state = {
            data: orgChartJson,
            totalNodeCount: countNodes(0, Array.isArray(orgChartJson) ? orgChartJson[0] : orgChartJson),
            orientation: 'vertical',
            dimensions: undefined,
            // centeringTransitionDuration: 800,
            translateX: 200,
            translateY: 300,
            collapsible: true,
            // shouldCollapseNeighborNodes: false,
            initialDepth: 25,
            depthFactor: undefined,
            zoomable: true,
            zoom: 1,
            scaleExtent: { min: 0, max: 5 },
            separation: { siblings: .9, nonSiblings: .5 },
            nodeSize: { x: 200, y: 200 },
            enableLegacyTransitions: true,
            transitionDuration: 500,
            renderCustomNodeElement: customNodeFnMapping['svg'].fn,
            // Above: From react-d3-tree
            flowsim_sim_name: "",
            flowsim_structure: [],
            flowsim_structure_loaded: false,
            flowsim_alpha: [],
            flowsim_alpha_loaded: false,
            text_node_fullname: "Click any node to select a population",
            slider_value: 0.50,
            node_prop_mean: 0,
        };

        this.setTreeData = this.setTreeData.bind(this);
        this.setLargeTree = this.setLargeTree.bind(this);
        this.setOrientation = this.setOrientation.bind(this);
        this.setPathFunc = this.setPathFunc.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleFloatChange = this.handleFloatChange.bind(this);
        this.toggleCollapsible = this.toggleCollapsible.bind(this);
        this.toggleZoomable = this.toggleZoomable.bind(this);
        this.setScaleExtent = this.setScaleExtent.bind(this);
        this.setSeparation = this.setSeparation.bind(this);
        this.setNodeSize = this.setNodeSize.bind(this);

        // Initialize the intraassay data
        this.get_set_FlowSimData("intraassay")
    }

    get_set_FlowSimData(sim_name) {
        var url = "/tree_json/?sim_name=" + sim_name
        if (debugging_urls) {
            url = "http://localhost:5001" + url
        }
        console.log(url)
        fetch(url, {
            method: "GET",
            headers: { 'Content-Type': 'application/json' },
        })
            .then((res) => res.json())
            .then((res) => {
                this.setState(
                    {
                        flowsim_sim_name: sim_name,
                        flowsim_structure: res,
                        flowsim_structure_loaded: true
                    },
                    () => {
                        console.log("FlowSim structure json loaded")
                        this.setTreeData(this.state.flowsim_structure)
                        console.log("TreeData set")
                    }
                );
            })
            .catch((error) => {
                console.error(error);
            })
    }

    get_FlowSimColor(sim_name, fullname, newmean) {
        var url = "/modified_dirichlet"
        if (debugging_urls) {
            url = "http://localhost:5001" + url
        }
        fetch(url, {
            method: "POST",
            headers: { 'Content-Type': 'application/json' },
            // body: JSON.stringify({ sim_name: 'intraassay', fullname: "/AllCells/CD4+/CD8-/Tem", mean: .8 })
            body: JSON.stringify({ sim_name: sim_name, fullname: fullname, mean: newmean })
        })
            .then((res) => res.json())
            .then((res) => {
                this.setState(
                    {
                        // flowsim_structure: debugTree,
                        flowsim_structure: res,
                        flowsim_structure_loaded: true
                    },
                    () => {
                        // console.log(res)
                        // console.log("FlowSim structure json loaded")
                        // this.setState({ renderCustomNodeElement: customNodeFnMapping["svg_no_toggle"].fn })
                        this.setTreeData(this.state.flowsim_structure)
                        // this.setState({ renderCustomNodeElement: customNodeFnMapping["svg"].fn })
                        // console.log("TreeData set")
                    }
                );
            })
            // .then((res)=> this.setState({ renderCustomNodeElement: customNodeFnMapping["svg"].fn }))
            .catch((error) => {
                console.error(error);
            });
    }
    simulate_single_sample(sim_name, n_cells) {
        // https://stackoverflow.com/questions/71191662/how-do-i-download-a-file-from-fastapi-backend-using-fetch-api-in-the-frontend
        var url = "/sim"
        if (debugging_urls) {
            url = "http://localhost:5001" + url
        }
        fetch(url, {
            method: "POST",
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ sim_name: sim_name, n_samples: "1", n_cells: n_cells, use_only_diagonal_covmat: "False" })
        })
            .then((res) => res.blob())
            .then((myblob) => {
                console.log(myblob)
                FileSaver.saveAs(myblob, "simulated_sample.fcs");
            })
    }

    setTreeData(data) {
        this.setState({
            data,
            totalNodeCount: countNodes(0, Array.isArray(data) ? data[0] : data),
        });
    }

    setLargeTree(data) {
        this.setState({
            data,
            transitionDuration: 0,
        });
    }

    setOrientation(orientation) {
        this.setState({ orientation });
    }

    setPathFunc(pathFunc) {
        this.setState({ pathFunc });
    }

    handleChange(evt) {
        const target = evt.target;
        const parsedIntValue = parseInt(target.value, 10);
        if (target.value === '') {
            this.setState({
                [target.name]: undefined,
            });
        } else if (!isNaN(parsedIntValue)) {
            this.setState({
                [target.name]: parsedIntValue,
            });
        }
    }

    handleFloatChange(evt) {
        const target = evt.target;
        const parsedFloatValue = parseFloat(target.value);
        if (target.value === '') {
            this.setState({
                [target.name]: undefined,
            });
        } else if (!isNaN(parsedFloatValue)) {
            this.setState({
                [target.name]: parsedFloatValue,
            });
        }
    }

    handleCustomNodeFnChange = evt => {
        const customNodeKey = evt.target.value;

        this.setState({ renderCustomNodeElement: customNodeFnMapping[customNodeKey].fn });
    };

    toggleCollapsible() {
        this.setState(prevState => ({ collapsible: !prevState.collapsible }));
    }

    // toggleCollapseNeighborNodes = () => {
    //     this.setState(prevState => ({
    //         shouldCollapseNeighborNodes: !prevState.shouldCollapseNeighborNodes,
    //     }));
    // };

    toggleZoomable() {
        this.setState(prevState => ({ zoomable: !prevState.zoomable }));
    }

    setScaleExtent(scaleExtent) {
        this.setState({ scaleExtent });
    }

    setSeparation(separation) {
        if (!isNaN(separation.siblings) && !isNaN(separation.nonSiblings)) {
            this.setState({ separation });
        }
    }

    setNodeSize(nodeSize) {
        if (!isNaN(nodeSize.x) && !isNaN(nodeSize.y)) {
            this.setState({ nodeSize });
        }
    }

    onSliderChange = (slider_value) => {
        this.setState({ slider_value }, () => { console.log(this.state.slider_value); });
        throttled_simcolor(this, slider_value)
    };

    render() {
        return (
            <div className="App">
                <div className="demo-container">
                    <div className="column-left">
                        <div className="controls-container">
                            <div className="prop-container">
                                <h1 className="title">Gating visualization</h1>
                                <h6 className="title">based on <a href="https://github.com/bkrem/react-d3-tree">React D3 Tree (v{version})</a></h6>
                                <h6 className="title">Version {vissim_version} <a href="https://git.uni-regensburg.de/ccc_verse/vissim">ccc_verse/vissim</a></h6>
                                <h4 className="prop">Node parameters</h4>
                                <div>
                                    <input type="text" value={this.state.text_node_fullname} onChange={this.handleChange} style={{ width: "370px", fontSize: "11px" }} />
                                </div>

                                {/* <div style={{ margin: 50 }}> */}
                                <div>

                                    <h6 className="prop">Mean</h6>
                                    {/* http://react-component.github.io/slider/?path=/story/rc-slider--slider */}
                                    <Slider
                                        min={0.0000000001}
                                        max={0.9999999999}
                                        step={.00001}
                                        startPoint={this.state.node_prop_mean}
                                        // value={this.state.slider_value}
                                        value={this.state.slider_value}
                                        onChange={this.onSliderChange}
                                    />
                                    <input
                                        type='number'
                                        step="0.0001"
                                        min='0.0000000001'
                                        max='0.9999999999'
                                        className='form-control'
                                        value={this.state.slider_value}
                                        onChange={(evt) => this.onSliderChange(evt.target.value)}
                                    />
                                    <button
                                        type="button"
                                        className="btn btn-controls btn-block"
                                        onClick={() => this.onSliderChange(this.state.node_prop_mean)}
                                    >
                                        Reset
                                    </button>
                                </div>

                                {/* Example datasets */}
                                <h4 className="prop">Datasets</h4>
                                {/* <div style={{ marginBottom: '5px' }}>
                                    <button
                                        type="button"
                                        className="btn btn-controls btn-block"
                                        onClick={() => this.setTreeData(orgChartJson)}
                                    >
                                        Org chart (small)
                                    </button>
                                </div> */}
                                <div>
                                    <button
                                        type="button"
                                        className="btn btn-controls btn-block"
                                        onClick={() => { this.get_set_FlowSimData("intraassay") }}
                                    >
                                        Intraassay tree
                                    </button>
                                    {/* <button
                                        type="button"
                                        className="btn btn-controls btn-block"
                                        onClick={() => {
                                            // this.get_FlowSimColor("intraassay")
                                            this.get_FlowSimColor("intraassay", "/AllCells/CD4+/CD8-/Tem", .8)
                                        }}
                                    >
                                        Debug: Intraassay tree, Update colors
                                    </button> */}
                                </div>
                            </div>

                            <div>
                                <h4 className="prop">Simulations</h4>
                                <h5 className="prop">Intraassay</h5>
                                <div>
                                    <button
                                        type="button"
                                        className="btn btn-controls btn-block"
                                        onClick={() => {
                                            this.simulate_single_sample("intraassay", "10000")
                                        }}
                                    >
                                        Download .fcs: 10k
                                    </button>
                                </div>
                            </div>

                            {/* Orientation controls */}
                            <div className="prop-container">
                                <h4 className="prop">Orientation</h4>
                                <button
                                    type="button"
                                    className="btn btn-controls btn-block"
                                    onClick={() => this.setOrientation('horizontal')}
                                >
                                    {'Horizontal'}
                                </button>
                                <button
                                    type="button"
                                    className="btn btn-controls btn-block"
                                    onClick={() => this.setOrientation('vertical')}
                                >
                                    {'Vertical'}
                                </button>
                            </div>

                            {/* Path Function controls */}
                            {/* <div className="prop-container">
                                <h4 className="prop">Path Function</h4>
                                <button
                                    type="button"
                                    className="btn btn-controls btn-block"
                                    onClick={() => this.setPathFunc('diagonal')}
                                >
                                    {'Diagonal'}
                                </button>
                                <button
                                    type="button"
                                    className="btn btn-controls btn-block"
                                    onClick={() => this.setPathFunc('elbow')}
                                >
                                    {'Elbow'}
                                </button>
                                <button
                                    type="button"
                                    className="btn btn-controls btn-block"
                                    onClick={() => this.setPathFunc('straight')}
                                >
                                    {'Straight'}
                                </button>
                                <button
                                    type="button"
                                    className="btn btn-controls btn-block"
                                    onClick={() => this.setPathFunc('step')}
                                >
                                    {'Step'}
                                </button>
                            </div> */}

                            {/* <div className="prop-container">
                                <label className="prop" htmlFor="customNodeElement">
                                    Custom Node Element
                                </label>
                                <select className="form-control" onChange={this.handleCustomNodeFnChange}>
                                    {Object.entries(customNodeFnMapping).map(([key, { description }]) => (
                                        <option key={key} value={key}>
                                            {description}
                                        </option>
                                    ))}
                                </select>
                            </div> */}

                            <div className="prop-container">
                                <h4 className="prop">Collapsible</h4>
                                <Switch
                                    name="collapsibleBtn"
                                    checked={this.state.collapsible}
                                    onChange={this.toggleCollapsible}
                                />
                            </div>

                            {/* <div className="prop-container">
                                <h4 className="prop">Zoomable</h4>
                                <Switch
                                    name="zoomableBtn"
                                    checked={this.state.zoomable}
                                    onChange={this.toggleZoomable}
                                />
                            </div>


                            <div className="prop-container">
                                <h4 className="prop">Animated transitions</h4>
                                <Switch
                                    name="enableLegacyTransitionsBtn"
                                    checked={this.state.enableLegacyTransitions}
                                    onChange={() =>
                                        this.setState(prevState => ({
                                            enableLegacyTransitions: !prevState.enableLegacyTransitions,
                                        }))
                                    }
                                />
                                <label className="prop" htmlFor="transitionDuration">
                                    Transition Duration
                                </label>
                                <input
                                    className="form-control"
                                    name="transitionDuration"
                                    type="number"
                                    value={this.state.transitionDuration}
                                    onChange={this.handleChange}
                                />
                            </div>
                            <div className="prop-container">
                                <div>
                                    <label className="prop" htmlFor="translateX">
                                        Translate X
                                    </label>
                                    <input
                                        className="form-control"
                                        name="translateX"
                                        type="number"
                                        value={this.state.translateX}
                                        onChange={this.handleChange}
                                    />
                                </div>
                                <div>
                                    <label className="prop" htmlFor="translateY">
                                        Translate Y
                                    </label>
                                    <input
                                        className="form-control"
                                        name="translateY"
                                        type="number"
                                        value={this.state.translateY}
                                        onChange={this.handleChange}
                                    />
                                </div>
                            </div> */}

                            <div className="prop-container">
                                <label className="prop" htmlFor="initialDepth">
                                    Initial Depth <br></br>(Reload data to activate)
                                </label>
                                <input
                                    className="form-control"
                                    style={{ color: 'grey' }}
                                    name="initialDepth"
                                    type="text"
                                    value={this.state.initialDepth}
                                    onChange={this.handleChange}
                                />
                            </div>

                            {/* <div className="prop-container">
                                <label className="prop" htmlFor="depthFactor">
                                    Depth Factor
                                </label>
                                <input
                                    className="form-control"
                                    name="depthFactor"
                                    type="number"
                                    defaultValue={this.state.depthFactor}
                                    onChange={this.handleChange}
                                />
                            </div> */}

                            {/* <div className="prop-container prop">{`Zoomable: ${this.state.zoomable}`}</div> */}

                            <div className="prop-container">
                                <label className="prop" htmlFor="zoom">
                                    Zoom
                                </label>
                                <input
                                    className="form-control"
                                    name="zoom"
                                    type="number"
                                    defaultValue={this.state.zoom}
                                    onChange={this.handleFloatChange}
                                />
                            </div>

                            {/* <div className="prop-container">
                                <span className="prop prop-large">Scale Extent</span>
                                <label className="sub-prop" htmlFor="scaleExtentMin">
                                    Min
                                </label>
                                <input
                                    className="form-control"
                                    name="scaleExtentMin"
                                    type="number"
                                    defaultValue={this.state.scaleExtent.min}
                                    onChange={evt =>
                                        this.setScaleExtent({
                                            min: parseFloat(evt.target.value),
                                            max: this.state.scaleExtent.max,
                                        })
                                    }
                                />
                                <label className="sub-prop" htmlFor="scaleExtentMax">
                                    Max
                                </label>
                                <input
                                    className="form-control"
                                    name="scaleExtentMax"
                                    type="number"
                                    defaultValue={this.state.scaleExtent.max}
                                    onChange={evt =>
                                        this.setScaleExtent({
                                            min: this.state.scaleExtent.min,
                                            max: parseFloat(evt.target.value),
                                        })
                                    }
                                />
                            </div> */}

                            <div className="prop-container">
                                <span className="prop prop-large">Node separation</span>
                                <label className="sub-prop" htmlFor="separationSiblings">
                                    Siblings
                                </label>
                                <input
                                    className="form-control"
                                    name="separationSiblings"
                                    type="number"
                                    defaultValue={this.state.separation.siblings}
                                    onChange={evt =>
                                        this.setSeparation({
                                            siblings: parseFloat(evt.target.value),
                                            nonSiblings: this.state.separation.nonSiblings,
                                        })
                                    }
                                />
                                {/* <label className="sub-prop" htmlFor="separationNonSiblings">
                                    Non-Siblings
                                </label>
                                <input
                                    className="form-control"
                                    name="separationNonSiblings"
                                    type="number"
                                    defaultValue={this.state.separation.nonSiblings}
                                    onChange={evt =>
                                        this.setSeparation({
                                            siblings: this.state.separation.siblings,
                                            nonSiblings: parseFloat(evt.target.value),
                                        })
                                    }
                                /> */}
                            </div>

                            {/* <div className="prop-container">
                                <span className="prop prop-large">Node size</span>
                                <label className="sub-prop" htmlFor="nodeSizeX">
                                    X
                                </label>
                                <input
                                    className="form-control"
                                    name="nodeSizeX"
                                    type="number"
                                    defaultValue={this.state.nodeSize.x}
                                    onChange={evt =>
                                        this.setNodeSize({ x: parseFloat(evt.target.value), y: this.state.nodeSize.y })
                                    }
                                />
                                <label className="sub-prop" htmlFor="nodeSizeY">
                                    Y
                                </label>
                                <input
                                    className="form-control"
                                    name="nodeSizeY"
                                    type="number"
                                    defaultValue={this.state.nodeSize.y}
                                    onChange={evt =>
                                        this.setNodeSize({ x: this.state.nodeSize.x, y: parseFloat(evt.target.value) })
                                    }
                                />
                            </div> */}
                        </div>
                    </div>

                    <div className="column-right">
                        <div className="tree-stats-container">
                            Total nodes in tree: {this.state.totalNodeCount}
                        </div>
                        <div ref={tc => (this.treeContainer = tc)} className="tree-container">
                            <Tree
                                hasInteractiveNodes
                                data={this.state.data}
                                renderCustomNodeElement={
                                    this.state.renderCustomNodeElement
                                        ? rd3tProps => this.state.renderCustomNodeElement(rd3tProps, this.state)
                                        : undefined
                                }
                                // rootNodeClassName="demo-node"
                                // branchNodeClassName="demo-node"
                                // leafNodeClassName="demo-node"

                                // rootNodeClassName="node__root"
                                // branchNodeClassName="node__branch"
                                // leafNodeClassName="node__leaf"

                                orientation={this.state.orientation}
                                dimensions={this.state.dimensions}
                                // centeringTransitionDuration={this.state.centeringTransitionDuration}
                                translate={{ x: this.state.translateX, y: this.state.translateY }}
                                pathFunc={this.state.pathFunc}
                                collapsible={this.state.collapsible}
                                initialDepth={this.state.initialDepth}
                                zoomable={this.state.zoomable}
                                zoom={this.state.zoom}
                                scaleExtent={this.state.scaleExtent}
                                nodeSize={this.state.nodeSize}
                                separation={this.state.separation}
                                enableLegacyTransitions={this.state.enableLegacyTransitions}
                                transitionDuration={this.state.transitionDuration}
                                depthFactor={this.state.depthFactor}
                                styles={this.state.styles}
                                // shouldCollapseNeighborNodes={this.state.shouldCollapseNeighborNodes}
                                // onUpdate={(...args) => {console.log(args)}}
                                // onNodeMouseOver={(...args) => {
                                //     console.log('onNodeMouseOver', args);
                                // }}
                                // onNodeMouseOut={(...args) => {
                                //     console.log('onNodeMouseOut', args);
                                // }}
                                onNodeClick={(nodeDatum, event) => {
                                    // console.log('onNodeClick', "data" in nodeDatum ? nodeDatum.data.fullname : nodeDatum.fullname, nodeDatum, event);
                                    this.setState({ text_node_fullname: "data" in nodeDatum ? nodeDatum.data.fullname : nodeDatum.fullname })
                                    this.setState({
                                        node_prop_mean: "data" in nodeDatum ? nodeDatum.data.prop_mean : nodeDatum.prop_mean,
                                        slider_value: "data" in nodeDatum ? nodeDatum.data.prop_mean : nodeDatum.prop_mean
                                    })
                                }}
                                onLinkClick={(...args) => {
                                    console.log('onLinkClick');
                                    console.log(args);
                                }}
                                onLinkMouseOver={(...args) => {
                                    console.log('onLinkMouseOver', args);
                                }}
                                onLinkMouseOut={(...args) => {
                                    console.log('onLinkMouseOut', args);
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div >
        );
    }
}

export default App;
